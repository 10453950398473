<template>
  <div id="app">
    <div id="container">
      <Header :name="layout[0].name" />
      <Home :layout="layout" :lotto="lotto" />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./views/Header.vue";
import Home from "./views/Home.vue";

export default {
  name: "app",

  components: { Header, Home },
  data() {
    return { layout: null, lotto: null };
  },

  created() {
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/lotto-reward/1`)
      .then(({ data }) => {
        this.lotto = data;
      })
      .catch((err) => {
        return err;
      });

    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/lotto/1`)
      .then(({ data }) => {
        this.layout = data;
      })
      .catch((err) => {
        return err;
      });
  },
  methods: {},
};
</script>

<style>
html {
  background: #110000;
}
#app {
  font-family: "Prompt", sans-serif;
  background: #110000;
}
#header {
  margin: 0px auto;
  width: 800px;
  text-align: center;
}

#container {
  width: 60%;
  /*height: 550px; */
  margin: 0px auto;
  padding: 10px;
  background: #ffffff;
}
thead {
  background: #110000;
  color: #ffffff;
}
th {
  font-size: 14px;
}
td {
  font-size: 14px;
}

@media (max-width: 575.98px) {
  #container {
    width: 100%;
  }
}
</style>
